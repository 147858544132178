<template>
  <div class="festival-apps">
    <div class="festival-apps__app-container">
      <music-artist-app />
    </div>
  </div>
</template>

<script>
import MusicArtistApp from "@/components/MusicArtistApp.vue";
export default {
  components: {
    MusicArtistApp,
  },
};
</script>

<style lang="scss" scoped>
.festival-apps {
  text-align: left;
  &__app-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    padding: 1rem;
  }
}
</style>
