<template>
  <div class="artist-profile">
    <div v-if="!!artist" class="artist-card">
      <div class="artist-card__image">
        <img :src="artist.mainImage" :alt="artist.name" />
      </div>
      <div class="artist-card__content">
        <div class="artist-card__bio">
          {{ artist.bio }}
        </div>
        <div class="artist-card__website">
          <a :href="artist.website"></a>
        </div>
      </div>
    </div>
    <div v-else>Artist Not Found.</div>
  </div>
</template>

<script>
import artists from "@/assets/json/artists.json";
export default {
  computed: {
    artist() {
      return artists[this.$route.params.id];
    },
  },
};
</script>

<style lang="scss" scoped>
.artist-profile {
  color: var(--color-dark-grey);
  font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
  background: url("../assets/img/HeroBackground1920.jpeg");
  background-size: cover;
  padding: 0 1rem;
  min-height: 100vh;
}
</style>
