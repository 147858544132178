<template>
  <div class="musical-artists pb-5">
    <h1 class="viva pt-5 mb-6">Musical Artists</h1>
    <div v-for="artist in artists" :key="artist.name">
      <div class="artist-link mt-4">{{ artist.name }}</div>
      <!-- <router-link class="artist-link" :to="`/artist/${artist.id}`">
        {{ artist.name }}
      </router-link> -->
    </div>
  </div>
</template>

<script>
import artists from "@/assets/json/artists.json";
export default {
  data() {
    return {
      artists,
    };
  },
};
</script>

<style lang="scss" scoped>
.musical-artists {
  background: url("../assets/img/melted-vert.jpg");
  background-size: contain;
  background-position: left;
  padding: 0 1rem;
  min-height: 100vh;
}
.viva {
  font-family: "VivaKaiva", "Helvetica", "sans-serif";
}
h1 {
  font-size: 5em;
  color: #fff;
  font-weight: bold;
  line-height: 1.02em;
}
.artist-link {
  font-size: 2em;
  font-family: "ArchivoBlack", Arial, Helvetica, sans-serif;
  color: var(--color-offwhite);
  line-height: 1.02em;
}
</style>
